import { UploadedFile } from '../types/Field';
import { ISelectOption } from './helper';

export enum UserRole {
  Client = 'CLIENT',
  Provider = 'CONTRACTOR',
  // sort of a meta user who can create and manage their own clients
  Manager = 'MANAGER',
  Admin = 'ADMIN',
  Pending = 'PENDING',
}

export const userRoleReadableString = {
  [UserRole.Client]: 'Requester',
  [UserRole.Provider]: 'Provider',
  [UserRole.Manager]: 'Representative',
  [UserRole.Admin]: 'Platform Administrator',
  [UserRole.Pending]: 'Pending',
};

export enum UserActivationStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
}

export enum ProviderType {
  Contractor = 'CONTRACTOR',
  Clinician = 'CLINICIAN',
}

export enum ProviderTypeLabel {
  Contractor = 'Contractor',
  Clinician = 'Clinician',
}

export const providerTypeReadableString = {
  [ProviderType.Contractor]: ProviderTypeLabel.Contractor,
  [ProviderType.Clinician]: ProviderTypeLabel.Clinician,
};

export interface IUserInfo {
  [key: string]: any;

  id?: string;
  firstName?: string;
  lastName?: string;
  role?: UserRole;
  status?: UserActivationStatus;
  email?: string;
  rating?: number;
  streetAddress?: string;
  aptSuiteNumber?: string;
  city?: string;
  state?: string;
  phone?: string;
  canCall?: boolean;
  canText?: boolean;
  zipCode?: number;
  backgroundPicture?: string;
  profilePicture?: string;
  savedUsers?: string[];
}

export enum ProfileLinkType {
  OwnWebsite = 'website',
  Instagram = 'instagram',
  Facebook = 'facebook',
  Yelp = 'yelp',
}

export enum WeekDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export const weekDayReadableString = {
  [WeekDay.Monday]: 'Monday',
  [WeekDay.Tuesday]: 'Tuesday',
  [WeekDay.Wednesday]: 'Wednesday',
  [WeekDay.Thursday]: 'Thursday',
  [WeekDay.Friday]: 'Friday',
  [WeekDay.Saturday]: 'Saturday',
  [WeekDay.Sunday]: 'Sunday',
};

export interface IProfileLink {
  service: ProfileLinkType;
  link: string;
}

export interface IOpenDaySchedule {
  day: WeekDay;
  isOpen: true;
  startTime: string; // string having format hh:mm
  endTime: string; // string having format hh:mm
}

export interface IClosedDaySchedule {
  day: WeekDay;
  isOpen: false;
}

export type DaySchedule = IOpenDaySchedule | IClosedDaySchedule;

export interface IServiceOption extends ISelectOption {
  subServices: string[];
}

export interface IProviderInfo extends IUserInfo {
  role: UserRole.Provider;
  services?: IServiceOption[];
  serviceAreaDistance?: string; // representation of a number
  companyName: string;
  companyOverview?: string;
  certifications?: string[];
  profileLinks?: IProfileLink[];
  workingHours?: DaySchedule[];
  featuredWork?: UploadedFile[];
  providerType?: ProviderType[];
}

export interface IClientInfo extends IUserInfo {
  role: UserRole.Client;
  birthYear?: number;
  managedById?: string;
}

export interface IContactInfo extends IUserInfo {
  role: UserRole.Client | UserRole.Manager;
  managedById?: string;
}

export interface IManagerInfo extends IUserInfo {
  role: UserRole.Manager;
}

export type IUserInfoUnion = IClientInfo | IProviderInfo | IManagerInfo;
