var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"uAHc91uNNEja3joKyJBDr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

        environment: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
        release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,

        // This enables automatic instrumentation (highly recommended),
        // but is not necessary for purely manual usage
        // If you only want to use custom instrumentation:
        // * Remove the `BrowserTracing` integration
        // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
        // integrations: [new Sentry.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // tracesSampleRate: 0.01,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/rosariumhealth\.com\/api/,
            /^https:\/\/rosarium\.dev\/api/,
        ],
    });
}
